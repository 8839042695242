/**
 * Функция validationTool проверяет инструмент на соответствие определенным критериям.
 * @param {Object} tool - Объект, представляющий инструмент.
 * @param {string} tool.id - Уникальный идентификатор инструмента.
 * @param {string} tool.brand - Бренд инструмента.
 * @param {string} tool.tool_name - Идентификатор инструмента.
 * @param {string} tool.type_tool - Тип инструмента (например, MILL).
 * @param {string} tool.construction - Конструкция инструмента (например, монолит).
 * @param {number} tool.count - Общее количество инструментов.
 * @param {number} tool.used_count - Количество использованных инструментов.
 * @param {number} tool.need_count - Необходимое количество инструментов.
 * @param {string} tool.about_tool - Комментарий о инструменте.
 * @param {string} tool.diameter - Диаметр инструмента.
 * @param {number} tool.cutting_length - Длина инструмента.
 * @param {string} tool.processing_material - Обрабатываемый материал (например, P).
 * @param {string} tool.registration_date - Дата регистрации инструмента.
 * @param {string} tool.update_date - Дата последнего обновления информации об инструменте.
 * @returns {boolean} - Возвращает true, если инструмент соответствует критериям.
 */
export function toolValidation(tool, originalTool) {
  if (
    tool.tool_name.trim() === "" ||
    tool.diameter.trim() === "" ||
    tool.type_tool.trim() === ""
  ) {
    return false;
  }

  if (originalTool !== null) {
    if (
      tool.brand.trim() === originalTool.brand.trim() &&
      tool.tool_name.trim() === originalTool.tool_name.trim() &&
      tool.type_tool.trim() === originalTool.type_tool.trim() &&
      tool.construction.trim() === originalTool.construction.trim() &&
      tool.count.toString().trim() === originalTool.count.toString().trim() &&
      tool.used_count.toString().trim() ===
        originalTool.used_count.toString().trim() &&
      tool.need_count.toString().trim() ===
        originalTool.need_count.toString().trim() &&
      tool.about_tool.trim() === originalTool.about_tool.trim() &&
      tool.diameter.trim() === originalTool.diameter.trim() &&
      tool.cutting_length.toString().trim() ===
        originalTool.cutting_length.toString().trim() &&
      tool.processing_material.trim() ===
        originalTool.processing_material.trim()
    ) {
      return false;
    }
  }

  return true;
}
