import { jwtDecode } from "jwt-decode";

/**
 * Получает значение указанного cookie.
 *
 * @param {string} name - Имя cookie, которое нужно получить.
 * @returns {string|null} - Значение cookie, если найдено, иначе null.
 *
 * Эта функция ищет среди cookies документа cookie с указанным именем.
 * Она удаляет начальные пробелы и проверяет, начинается ли cookie с
 * данного имени, за которым следует знак равенства. Если найдено,
 * возвращает значение cookie; в противном случае возвращает null.
 */
export function GetCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// // Пример использования
// const token = getCookie("token");
// console.log("Token---:", token);

///////////////////////////////////////////////////

export function UpdateToken(token) {
  const decodedToken = jwtDecode(token);
  const expiryTime = decodedToken.exp * 1000; // exp в Unix timestamp (секунды), умножаем на 1000 для мс
  const expiryDate = new Date(expiryTime);

  document.cookie = `token=${token}; path=/; expires=${expiryDate.toUTCString()};`;
  window.location.reload();
}

// export function UpdateToken(token) {
//   const date = new Date();
//   date.setMonth(date.getMonth() + 1); // Устанавливаем дату истечения через месяц
//   document.cookie = `token=${token}; path=/; expires=${date.toUTCString()};`;
//   window.location.reload();
// }

export function DeleteToken() {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
  window.location.reload();
}

// export async function DeleteToken() {
//   // Очищаем токен
//   document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";

//   // Ждем некоторое время (например, 1 секунду) для асинхронного выполнения
//   await new Promise(resolve => setTimeout(resolve, 1000));

//   // Перезагружаем страницу
//   window.location.reload();
// }
