import { Helmet, HelmetProvider } from "react-helmet-async";
import Button from "../components/button/Button";
import Notification from "../components/notification/Notification";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import { GetCookie } from "../utils/coockies";
import { Navigate } from "react-router-dom";
import { BaseAPIUrl } from "../config/config";
import { jwtDecode } from "jwt-decode";

export default function SinginPage() {
  const [dataNotification, setDataNotification] = useState({
    children: null,
    id: null,
    color: "red",
  });

  const [isLoading, setIsLoading] = useState(false); // Добавлено состояние загрузки
  const [loadingText, setLoadingText] = useState("Загрузка"); // Добавлено состояние для текста загрузки

  useEffect(() => {
    // Добавлено для динамической надписи загрузки
    let dotCount = 0;
    const interval = setInterval(() => {
      dotCount = (dotCount + 1) % 4;
      setLoadingText(`Загрузка${".".repeat(dotCount)}`);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  /**
   * Обновляет данные уведомления новыми значениями.
   *
   * @param {React.ReactNode} children - Контент, который будет отображаться в уведомлении.
   * @param {string} color - Цвет уведомления.
   */
  function handleNotification(children, color) {
    setDataNotification({
      children: children,
      id: uuidv4(),
      color: color,
    });
  }

  const [form, setForm] = useState({
    email: "",
    password: "",
    password2: "",
    username: "",
    licenseKey: "",
    hasError: true,
    hasErrorLogin: false,
    hasErrorPassword: false,
    hasErrorPassword2: false,
    hasErrorUsername: false,
    hasErrorLicenseKey: false,
  });

  function handleEmailChange(event) {
    setForm((prev) => ({
      ...prev,
      email: event.target.value,
      hasErrorLogin: event.target.value.trim().length <= 4,
    }));

    CheckForm("login", event.target.value.trim().length <= 4);
  }

  function handlePasswordChange(event) {
    setForm((prev) => ({
      ...prev,
      password: event.target.value,
      hasErrorPassword: event.target.value.trim().length <= 4,
    }));

    CheckForm("password", event.target.value.trim().length <= 4);
  }

  function handlePassword2Change(event) {
    setForm((prev) => ({
      ...prev,
      password2: event.target.value,
      hasErrorPassword2: event.target.value.trim().length <= 4,
    }));

    CheckForm("password2", event.target.value.trim().length <= 4);
  }

  function handleLicenseKeyChange(event) {
    setForm((prev) => ({
      ...prev,
      licenseKey: event.target.value,
      hasErrorLicenseKey: event.target.value.trim().length <= 4,
    }));

    CheckForm("licensekey", event.target.value.trim().length <= 4);
  }

  function handleUsernameChange(event) {
    setForm((prev) => ({
      ...prev,
      username: event.target.value,
      hasErrorUsername: event.target.value.trim().length <= 4,
    }));

    CheckForm("username", event.target.value.trim().length <= 4);
  }

  /**
   * Проверяет форму и обновляет состояние ошибок.
   * @param {string} nameValue - Имя поля формы.
   * @param {boolean} value - Значение ошибки для данного поля.
   */
  function CheckForm(nameValue, value) {
    let errors = {
      login: form.hasErrorLogin,
      password: form.hasErrorPassword,
      password2: form.hasErrorPassword2,
      licensekey: form.hasErrorLicenseKey,
      username: form.hasErrorUsername,
    };

    errors[nameValue] = value;

    if (
      !errors.login &&
      !errors.password &&
      !errors.password2 &&
      !errors.licensekey &&
      !errors.username &&
      form.email !== "" &&
      form.password2 !== "" &&
      form.username !== "" &&
      form.licenseKey !== "" &&
      form.password !== ""
    ) {
      setForm((prev) => ({
        ...prev,
        hasError: false,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        hasError: true,
      }));
    }
  }

  if (GetCookie("token") !== null) {
    return <Navigate to="/tools/" />;
  }

  return (
    <section>
      <HelmetProvider>
        <Helmet>
          <title>регистрация аккаунта</title>
        </Helmet>
      </HelmetProvider>

      <div className="login_blocform">
        <div className="login_form">
          <h2>Регистрация аккаунта</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault(); // Предотвращение перезагрузки страницы

              setIsLoading(true); // Устанавливаем состояние загрузки
              querySignup(
                form.email,
                form.password,
                form.password2,
                form.username,
                form.licenseKey
              ).then(([err, token]) => {
                setIsLoading(false); // Сбрасываем состояние загрузки
                if (err) {
                  handleNotification(err, "red");
                } else {
                  handleNotification("Выполнена регистрация", "green");
                  // const date = new Date();
                  // date.setMonth(date.getMonth() + 1); // Устанавливаем дату истечения через месяц
                  // document.cookie = `token=${token}; path=/; expires=${date.toUTCString()};`;

                  const decodedToken = jwtDecode(token);
                  const expiryTime = decodedToken.exp * 1000; // exp в Unix timestamp (секунды), умножаем на 1000 для мс
                  const expiryDate = new Date(expiryTime);

                  document.cookie = `token=${token}; path=/; expires=${expiryDate.toUTCString()};`;
                  window.location.reload();
                }
              });
            }}
          >
            <ul>
              <li>
                <label htmlFor="email">Логин</label>
                <input
                  type="text"
                  id="email"
                  className={`control ${form.hasErrorLogin ? "error" : ""}`}
                  value={form.email}
                  onChange={handleEmailChange}
                />
              </li>

              <li>
                <label htmlFor="password">Пароль</label>
                <input
                  type="password"
                  id="password"
                  className={`control ${form.hasErrorPassword ? "error" : ""}`}
                  value={form.password}
                  onChange={handlePasswordChange}
                />
              </li>

              <li>
                <label htmlFor="password2">Подтверждение пароля</label>
                <input
                  type="password"
                  id="password2"
                  className={`control ${form.hasErrorPassword2 ? "error" : ""}`}
                  value={form.password2}
                  onChange={handlePassword2Change}
                />
              </li>

              <li>
                <label htmlFor="username">Имя пользователя</label>
                <input
                  type="text"
                  id="username"
                  className={`control ${form.hasErrorUsername ? "error" : ""}`}
                  value={form.username}
                  onChange={handleUsernameChange}
                />
              </li>

              <li>
                <label htmlFor="licenseKey">Лицензионный ключ</label>
                <input
                  type="text"
                  id="licenseKey"
                  className={`control ${
                    form.hasErrorLicenseKey ? "error" : ""
                  }`}
                  value={form.licenseKey}
                  onChange={handleLicenseKeyChange}
                />
              </li>
            </ul>
            {/* <Button disabled={form.hasError} isActive={!form.hasError}>
              Отправить
            </Button>
          </form> */}
            <Button
              disabled={form.hasError || isLoading}
              isActive={!form.hasError && !isLoading}
            >
              {" "}
              {/* Изменено состояние кнопки  */}
              Отправить
            </Button>
          </form>
          {isLoading && <p className="loading-text">{loadingText}</p>}{" "}
        </div>
      </div>

      <Notification dataNotification={dataNotification}>
        {dataNotification.children}
      </Notification>
    </section>
  );
}

export async function querySignup(
  email,
  password,
  password2,
  username,
  license_key
) {
  let token = null;
  let err = null;

  try {
    const response = await fetch(`${BaseAPIUrl}api/signup/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        password2: password2,
        username: username,
        license_key: license_key,
      }),
    });

    const data = response.ok ? response.headers.get("Token") : null;

    switch (response.status) {
      case 200:
        token = data;
        break;
      case 401:
        err = "неверный логин или пароль";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 409:
        err = "данный пользватель уже зарегистрирован";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = `ошибка подключения к серверу ${error}`;
  }

  return [err, token];
}
