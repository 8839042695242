import { GetCookie, UpdateToken } from "../../../utils/coockies";
import { BaseAPIUrl } from "../../../config/config";

export async function queryUpdateInserts(insert) {
  const token = GetCookie("token");
  let err = null;
  let responseData = null;

  try {
    const params = new URLSearchParams({
      id: insert.id,
      brand: insert.brand.trim(),
      insert_name: insert.insert_name.trim(),
      type_insert: insert.type_insert.trim(),
      count: insert.count,
      used_count: insert.used_count,
      need_count: insert.need_count,
      about_insert: insert.about_insert.trim(),
      radius: insert.radius,
      cutting_length: insert.cutting_length,
      processing_material: insert.processing_material.trim(),
    });

    const url = `${BaseAPIUrl}api/insert/?${params.toString()}`;
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
    });

    const data = response.ok ? await response.json() : null;

    const newToken = response.headers.get("Token");
    if (newToken) {
      UpdateToken(newToken);
    }

    switch (response.status) {
      case 200:
        err = data.err;
        for (const row of data.insert) {
          responseData = row;
        }
        break;
      case 401:
        err = "пользователь неавторизирован";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = error;
  }

  return [err, responseData];
}
