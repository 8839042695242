import { GetCookie, UpdateToken, DeleteToken } from "../../../utils/coockies";
import { BaseAPIUrl } from "../../../config/config";

/**
 * Асинхронная функция для получения списка инструментов с сервера.
 *
 * Функция использует токен аутентификации, полученный из куки, для выполнения GET-запроса к API.
 * В зависимости от статуса ответа сервера, функция возвращает либо данные, либо сообщение об ошибке.
 *
 * @returns {Promise<[string|null, Array|null]>} Массив, содержащий сообщение об ошибке (если есть) и данные инструментов (если есть).
 *
 * Возможные значения возвращаемого массива:
 * - [null, Array] - успешный запрос, возвращает данные инструментов.
 * - ["пользователь неавторизирован", null] - ошибка 401, пользователь не авторизован.
 * - ["неправильный запрос", null] - ошибка 400, неправильный запрос.
 * - ["ошибка сервера", null] - ошибка 500, ошибка сервера.
 * - [`код ответа: ${response.status}`, null] - другие коды ответа.
 * - ["ошибка подключения к серверу ${error}", null] - ошибка подключения к серверу.
 */
export async function queryFetchTools() {
  const token = GetCookie("token");

  let dataTools = null;
  let err = null;

  try {
    const url = `${BaseAPIUrl}api/tools/`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
    });

    const newToken = response.headers.get("Token");
    if (newToken) {
      UpdateToken(newToken);
    }
 
    switch (response.status) {
      case 200:
        const data = response.ok ? await response.json() : null;
        dataTools = data;
        break;
      case 204:
        dataTools = [];
        break;
      case 401:
        err = "пользователь неавторизирован";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = `ошибка подключения к серверу ${error}`;
  }

  return [err, dataTools];
}
