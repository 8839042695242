import { Helmet, HelmetProvider } from "react-helmet-async";
import Button from "../components/button/Button";
import { GetCookie } from "../utils/coockies";
import { Navigate } from "react-router-dom";
import { BaseAPIUrl } from "../config/config";

export default function LogoutPage() {
  if (GetCookie("token") === null) {
    return <Navigate to="/singin/" />;
  }

  const handleLogout = async () => {
    const error = await queryRemoveToken();
    if (error) {
      console.error(error);
      // Optionally, display the error to the user
    }
    // else {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;";
    window.location.reload();
    // }
  };

  return (
    <section>
      <HelmetProvider>
        <Helmet>
          <title>выйти из аккаунта</title>
        </Helmet>
      </HelmetProvider>

      <h1>Выйти из аккаунта</h1>

      <Button onClick={handleLogout}>выход</Button>
    </section>
  );
}

////////////////////////////////////////////////

export async function queryRemoveToken() {
  const token = GetCookie("token");
  let err = null;

  try {
    const response = await fetch(`${BaseAPIUrl}api/logout/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: token,
      },
    });

    switch (response.status) {
      case 200:
        break;
      case 401:
        err = "неверный логин или пароль";
        break;
      case 400:
        err = "неправильный запрос";
        break;
      case 500:
        err = "ошибка сервера";
        break;
      default:
        err = `код ответа: ${response.status}`;
    }
  } catch (error) {
    err = `ошибка подключения к серверу ${error}`;
  }

  return err;
}
