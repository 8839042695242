import { v4 as uuidv4 } from "uuid";

export default function InsertForm(data) {
  return (
    <>
      <form className="update_form">
        <ul>
          <li key="brand">
            <label htmlFor="brand">Производитель</label>
            <input
              type="text"
              id="brand"
              value={data.formState.brand}
              onChange={data.handle}
              list="brands"
            />
            <datalist id="brands">
              {data.optionsState.brand.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
          <li key="insert_name">
            <label htmlFor="insert_name">Маркировка</label>
            <input
              type="text"
              id="insert_name"
              value={data.formState.insert_name}
              onChange={data.handle}
              className={` ${data.formState.hasErrorToolname ? "error" : ""}`}
              list="insert_names"
            />
            <datalist id="insert_names">
              {data.optionsState.insert_name.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
          <li key="type_insert">
            <label htmlFor="type_insert">Тип инструмента</label>
            <input
              type="text"
              id="type_insert"
              value={data.formState.type_insert}
              onChange={data.handle}
              className={` ${data.formState.hasErrorTypeTool ? "error" : ""}`}
              list="type_inserts"
            />
            <datalist id="type_inserts">
              {data.optionsState.type_insert.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
          <li key="count">
            <label htmlFor="count">Количество</label>
            <input
              id="count"
              type="number"
              step="1"
              min="0"
              max="200"
              value={data.formState.count}
              onChange={data.handle}
            />
          </li>
          <li key="used_count">
            <label htmlFor="used_count">Количество Б/У</label>
            <input
              id="used_count"
              type="number"
              step="1"
              min="0"
              max="200"
              value={data.formState.used_count}
              onChange={data.handle}
            />
          </li>
          <li key="need_count">
            <label htmlFor="need_count">Необходимое количество</label>
            <input
              id="need_count"
              type="number"
              step="1"
              min="0"
              max="200"
              value={data.formState.need_count}
              onChange={data.handle}
            />
          </li>
          <li key="about_insert">
            <label htmlFor="about_insert">Описание</label>
            <input
              type="text"
              id="about_insert"
              value={data.formState.about_insert}
              onChange={data.handle}
            />
          </li>
          <li key="radius">
            <label htmlFor="radius">Радиус кромки</label>
            <input
              type="number"
              step="0.1"
              min="0"
              max="300"
              id="radius"
              value={data.formState.radius}
              onChange={data.handle}
              className={` ${data.formState.hasErrorradius ? "error" : ""}`}
              list="radiuss"
            />
            <datalist id="radiuss">
              {data.optionsState.radius.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
          <li key="cutting_length">
            <label htmlFor="cutting_length">Длина режущей кромки</label>
            <input
              id="cutting_length"
              type="number"
              step="0.1"
              min="0"
              max="300"
              value={data.formState.cutting_length}
              onChange={data.handle}
              className={` ${data.formState.hasErrorLength ? "error" : ""}`}
            />
          </li>
          <li key="processing_material">
            <label htmlFor="processing_material">Материал обработки</label>
            <input
              type="text"
              id="processing_material"
              value={data.formState.processing_material}
              onChange={data.handle}
              list="processing_materials"
            />
            <datalist id="processing_materials">
              {data.optionsState.processing_material.map((row) => (
                <option key={uuidv4()} value={row}></option>
              ))}
            </datalist>
          </li>
        </ul>
      </form>
    </>
  );
}
