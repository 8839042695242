import { useNavigate } from "react-router-dom";
import React from "react";
import { useEffect } from "react";
import { BaseWEBUrl, BaseWEBUrl2 } from "../config/config";

import Button from "./button/Button";
import { GetCookie } from "../utils/coockies";

export default function TabsSection() {
  const token = GetCookie("token");
  const navigate = useNavigate();

  useEffect(() => {
    let currentPage = window.location.href.slice(BaseWEBUrl.length);
    let currentPage2 = window.location.href.slice(BaseWEBUrl2.length);

    if (
      token === null &&
      currentPage !== "singin/" &&
      currentPage2 !== "singin/" &&
      currentPage2 !== "singup/" &&
      currentPage !== "singup/"
    ) {
      navigate("/singin/");
    }
  }, [token, navigate]);

  function checkCurrentPage(path) {
    return (
      window.location.href.slice(BaseWEBUrl.length) === path ||
      window.location.href.slice(BaseWEBUrl2.length) === path
    );
  }

  return (
    <section style={{ marginBottom: "1rem", margin: "10px 0px 0px 0px" }}>
      {token !== null ? (
        <>
          <Button
            isActive={checkCurrentPage("tools/")}
            onClick={() => {
              navigate("/tools/");
            }}
          >
            инструмент
          </Button>
          <Button
            isActive={checkCurrentPage("inserts/")}
            onClick={() => {
              navigate("/inserts/");
            }}
          >
            пластины
          </Button>
          <Button
            isActive={checkCurrentPage("thistory/")}
            onClick={() => {
              navigate("/thistory/");
            }}
          >
            история инструмента
          </Button>
          <Button
            isActive={checkCurrentPage("ihistory/")}
            onClick={() => {
              navigate("/ihistory/");
            }}
          >
            история пластин
          </Button>
          <Button
            isActive={checkCurrentPage("logout/")}
            onClick={() => {
              navigate("/logout/");
            }}
          >
            выход
          </Button>
        </>
      ) : (
        <>
          <Button
            isActive={checkCurrentPage("singin/")}
            onClick={() => {
              navigate("/singin/");
            }}
          >
            вход
          </Button>
          <Button
            isActive={checkCurrentPage("singup/")}
            onClick={() => {
              navigate("/singup/");
            }}
          >
            регистрация
          </Button>
        </>
      )}
    </section>
  );
}
